











































































































































































































































































































































































.inputSpan {
  font-size: 0.3rem;
  color: #ff4b04;
  padding-top: 0.2rem;
  float: right;
}

.fdStyle {
  background: #f1f1f1;
  height: 100%;
  /* padding: 0.3rem; */
}

.van-ellipsis {
  color: #ffffff !important;
  /* background: green; */
}

// .van-nav-bar {
//   background: #ecbb34;
// }

.van-icon {
  color: #ffffff;
}

.endList {
  margin-top: 2.3rem;
}

.btnDivStyle {
  margin-top: 0.7rem;
  margin-bottom: 0.1rem;
  text-align: center;
}

.bodyStyle {
  background: #f1f1f1;
  height: 100%;
}

.vanCellStyle1 {
  /* margin-left: 0.4rem;
        margin-right: 0.4rem; */
  border-radius: 5px;
  margin-top: 0.2rem;
  /* background: #f1f1f1; */
}

.vanCellStyle2 {
  /* margin-left: 0.4rem;
        margin-right: 0.4rem; */
  border-radius: 5px;
  margin-top: 0.3rem;
  /* background: #f1f1f1; */
}

.van-icon-arrow-left {
  color: #ffffff;
}

// .van-hairline--bottom {
//   background: #ecbb34;
//   color: #ffffff;
// }

// .van-nav-bar__title {
//   background: #ecbb34;
//   color: #ffffff;
// }

/* .van-ellipsis {
      background: 3d8051;
    } */


.inputPapaStyle {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #f2f2f2;
}

.inputStyle {
  border: 0px;
  /* border-top: 0px;
        border-left: 0px;
        border-right: 0px; */
  width: 100%;
  /* border-bottom: 1px solid #f2f2f2; */
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  color: #8b7575;
  padding: 0.1rem;
}

.whole {
  // background-color: #F7F7FA;
}

.title {
  background: #f6f8f9;
  height: 2rem;
  padding: 0.3rem;
}

.titleGuanLiStyle {
  font-size: 0.4rem;
  padding-top: 1.5rem;
}

.titlePStyle {
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
  padding-top: 0.1rem;
  transform: rotate(180deg);
}

// ----------20200411[start]-----------
.layoutBox {
	min-height: calc(100vh - 0.68rem);
	// padding: 0 .3rem .5rem;
	margin: 0.2rem 0.2rem 0 0.2rem;
	font-family: PingFangSC-Regular;
}

.ulList {
	margin-top: 0.2rem;
	// padding: 0 0.3rem;
	.list-cell {
		height: 1.18rem;
		line-height: 1.18rem;
		padding: 0 0.25rem;
		background: #ffffff;
		border-radius: 8px;
		// margin-bottom: 0.1rem;
		position: relative;
		font-size: 0.28rem;
		color: #333333;
		font-weight: 500;
		.left {
			font-weight: 550;
		}
		.right {
			position: absolute;
			z-index: 1;
			right: 0.25rem;
			bottom: 0;
		}
	}
}

.contWhiteBox {
	background: #FFFFFF;
	border-radius: .12rem;
	margin-bottom: 10px;
}
.list-cell-item {
	margin-top: 0.2rem;
	padding: .3rem .25rem 0 .25rem;
	// border-bottom: 1px solid #EDEDED;
	line-height: .45rem;
	&:last-child {
		border-bottom: 0;
	}
	.hd {
		color: #333333;
		font-size: .28rem;
		display: flex;
		justify-content: space-between;
	}
	.bd {
		padding: 0.23rem 0;
		display: flex;
		justify-content: space-between;
		position: relative;
		font-size: 0.28rem;
		color: #333333;
	}
	.bd1 {
		padding: 0.23rem 0;
		display: flex;
		justify-content: space-between;
		position: relative;
		border-bottom: 0.01rem solid #D3D9DE;
	}
	.inp {
		color: #333333;
		font-size: .28rem;
		height: .45rem;
		display: block;
		width: 100%;
		flex: 1;
		border: 0;
		outline: 0;
	}
	input::-webkit-input-placeholder {
	    /* 修改字体颜色 */
	    color: #999999;
	    /* 修改字号，默认继承input */
	    font-size: 12px;
	}
	.scanBtn {
		width: .45rem;
		height: .45rem;
		background: url(../../assets/imgs/new_color/saoma.png) no-repeat center center;
		background-size: .4rem .4rem;
	}
}
.list-cell-item1 {
	margin: 0 0.2rem;
	padding: 0.16rem 0;
	// border-bottom: 1px solid #EDEDED;
	line-height: .45rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	&:last-child {
		border-bottom: 0;
	}
	.hd {
		color: #333333;
		font-size: .28rem;
		display: flex;
		justify-content: space-between;
	}
	.bd {
		padding: 0.23rem 0;
		display: flex;
		justify-content: space-between;
		position: relative;
	}
	.bd1 {
		padding: 0.23rem 0;
		display: flex;
		justify-content: space-between;
		position: relative;
		border-bottom: 0.01rem solid #D3D9DE;
	}
	.inp {
		color: #999999;
		font-size: .24rem;
		height: .45rem;
		display: block;
		width: 100%;
		flex: 1;
		border: 0;
		outline: 0;
	}
	input::-webkit-input-placeholder,
	input:-moz-placeholder,
	input::-moz-placeholder,
	input:-ms-input-placeholder {
		color: #999999;
	}
	.scanBtn {
		width: .45rem;
		height: .45rem;
		background: url(../../assets/imgs/new_color/saoma.png) no-repeat center center;
		background-size: .4rem .4rem;
	}
}
.comTips {
	margin-left: -0.25rem;
	width: 7.5rem;
	color: #6F7A8B;
	font-size: .24rem;
	transform: scale(0.83);
	line-height: .45rem;
}
.comBtnStyle {
	color: #FFFFFF;
	font-size: .32rem;
	text-align: center;
	height: 1rem;
	line-height: 1rem;
	background: linear-gradient(131deg, #527EFF 0%, #5569FC 100%);
	border-radius: 0.16rem;
}
.mainFontLightColor {
	margin-right: 0.1rem;
	color: #666666 !important;
	font-size: 0.24rem;
}
.icon_arrR {
	width: .2rem;
	height: .2rem;
	border-top: .04rem solid #666;
	border-right: .04rem solid #666;
}
.mainFontColor {
	color: #333333;
	font-size: 0.28rem;
	font-weight: 550;
}
.mainFontLighterColor {
	color: #666666;
	font-size: 0.24rem;
}
.allBtn {
	font-size: 0.24rem;
}
// ----------20200411[end]-----------

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;